import PuxNewsletterForm from '../PuxNewsletterForm/PuxNewsletterForm'
import React, { FunctionComponent, useState } from 'react'
import { StickyContainer } from 'react-sticky'
import { ToastContainer } from 'react-toastify'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import { getLocalizedData } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import MauticInit from '../MauticForms/MauticInit'
import Footer from './Footer'
import Header from './Header'
import { HeaderData } from './HeaderNav'
import EasySoftwareInfoBar from 'Root/Shared/components/EasySoftwareInfoBar/EasySoftwareInfoBar'
import { IFooterData, IHeaderData } from 'Root/Shared/queries/layout-queries'
import { useEditorStatus } from 'Root/Shared/utils/edit/useEditorStatus'
import EasySoftwareEditorBar from 'Root/Shared/components/EasySoftwareEditorBar/EasySoftwareEditorBar'

interface LayoutPropsType {
  customLayoutClass?: string
  style?: string
  cta?: LinkType
  landingPageNav?: HeaderData
  localizedPath?: localizedPathType[]
  footerData: IFooterData
  headerData: IHeaderData
  pageContentItemId: string
}

const Layout: FunctionComponent<LayoutPropsType> = (props) => {
  const [customHeaderClass, setCustomHeaderClass] = useState(``)
  const getHeaderCustomClass = (duplicatedNav: boolean): void => {
    setCustomHeaderClass(duplicatedNav ? `has-megamenu` : ``)
  }

  const classes = [`layout-main`, props.customLayoutClass, customHeaderClass]

  const { mautic, hasOrchardForms } = getLocalizedData(
    process.env.LOCALE || ``,
    process.env.PROJECT || ``
  )


  if (hasOrchardForms !== "GATSBY_EN_HAS_ORCHARD_FORM" ? hasOrchardForms : process.env.GATSBY_EN_HAS_ORCHARD_FORM === 'true') {
    console.log("Mautic script core disabled")
  } else {
    console.log("Mautic script core enabled")
    MauticInit(mautic.src, mautic.domain, t`Mautic.Submitting.Message`)
  }

  const isEditor = useEditorStatus()

  return (
    <>
      {(t`Redmine.InfoBar.Text` !== `Redmine.InfoBar.Text`) && !isEditor &&
        <EasySoftwareInfoBar />
      }
      {isEditor &&
        <EasySoftwareEditorBar pageContentItemId={props.pageContentItemId} />
      }
      <StickyContainer>
        <Header
          customHeaderFnc={getHeaderCustomClass}
          customHeaderClass={props.customLayoutClass}
          style={props.style}
          cta={props.cta}
          landingPageNav={props.landingPageNav}
          localizedPaths={props.localizedPath}
          headerData={props.headerData}
        />
        <main className={classes.join(` `)}>{props.children}</main>
        <ToastContainer />
        <PuxNewsletterForm />
        <Footer footerData={props.footerData} />
      </StickyContainer>
    </>
  )
}

export default Layout
