import './KnowledgeBase.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareSearch from 'Root/Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import PuxLink from 'Root/Shared/utils/PuxLink'
import GoogleSearch from 'Shared/components/GoogleSearch/GoogleSearch'
import {
  GenericPageProps,
  IEasySoftwareKnowledgeBase,
} from 'Shared/queries/page-queries'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const KnowledgeBase: FunctionComponent<
  GenericPageProps<IEasySoftwareKnowledgeBase>
> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
      pageProperties={props.pageContext.pageProperties}
    >
      <div className='Container KnowledgeBase'>
        <div className='KnowledgeBase-header'>
          <h1>{pageData.displayText}</h1>
          <GoogleSearch searchType='knowledgebase' />
        </div>
        <div className='KnowledgeBase-container'>
          {props.pageContext?.knowledgeBaseData?.map((item, index) => (
            <PuxLink
              className='KnowledgeBase-item'
              to={getLocalizedUrl(item.path)}
            >
              <span>
                {index < 9 ? `0` + (index + 1).toString() : index + 1}
              </span>
              <h2>{item.displayTextWithoutNumber}</h2>
            </PuxLink>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default KnowledgeBase

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareKnowledgeBase>>
) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return (
    <PuxMetaTagsGatsby
      {...metaTags}
      localizedPaths={pageData.localization?.localizations}
    />
  )
}
