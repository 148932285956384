import './KnowledgeBaseArticle.scss'

import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxContent from 'Root/Shared/components/PuxContent/PuxContent'
import {
  GenericPageProps,
  IEasySoftwareKnowledgeBaseArticle,
} from 'Root/Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import PuxMetaTagsGatsby from 'Root/Shared/utils/PuxMetaTagsGatsby'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareSideCardNavigation from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'

const KnowledgeBaseArticle: FunctionComponent<
  GenericPageProps<IEasySoftwareKnowledgeBaseArticle>
> = (props) => {
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const knowledgeBaseData = props.pageContext.knowledgeBaseData
  const pageData = props.pageContext.pageData
  const editorPath = `easySoftwareKnowledgeBaseArticle,knowledgeBaseArticleContent,html`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
      pageProperties={props.pageContext.pageProperties}
    >
      <div className='Container KnowledgeBaseArticle'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <PuxContent
          contentModifier='knowledgeBaseArticle'
          sideContent={
            <EasySoftwareSideCardNavigation
              type='knowledgeBase'
              sideCardNavigationLinks={knowledgeBaseData}
            />
          }
          sideCards={props.pageContext.sideCardWidgets}
        >
          <h1>{pageData?.displayText}</h1>

          <div className='KnowledgeBaseArticle-tags'>
            {pageData.knowledgeBaseArticleTags?.termContentItems?.map(
              (item) => {
                if (item) {
                  return (
                    <div className='KnowledgeBaseArticle-tag-item'>
                      {item?.displayText}
                    </div>
                  )
                }
              }
            )}
          </div>
          <EasySoftwareEditorContainer
            content={pageData.knowledgeBaseArticleContent}
            editorPath={editorPath}
            pageContentItemId={pageData.contentItemId}
          />
        </PuxContent>
      </div>
    </Layout>
  )
}

export default KnowledgeBaseArticle

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareKnowledgeBaseArticle>>
) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return (
    <PuxMetaTagsGatsby
      {...metaTags}
      localizedPaths={pageData.localization?.localizations}
    />
  )
}
