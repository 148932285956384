/* eslint-disable prettier/prettier */
import { useLocation } from '@reach/router'
import { decode } from 'html-entities'
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { MenuCategory, MenuItem, TopMenuItem } from 'Shared/utils/menuItemsMapper'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'
import PuxLink from 'Shared/utils/PuxLink'
import PuxIconBuilder from '../PuxIconBuilder/PuxIconBuilder'

export interface HeaderData {
  menuItems: TopMenuItem[]
}
interface HeaderNavProps {
  headerData: HeaderData
}
export interface renderData {
  url: string
  name: string
  internal?: boolean
}

export interface MenuDuplicate {
  main: MenuItem[]
  nav: MenuItem[]
}

const addHoveredClass = (menuItem: Element) => {
  return menuItem.classList.add(`is-hovered`)
}

const removeHoveredClass = (menuItem: Element) => {
  setTimeout(() => {
    return menuItem.classList.remove(`is-hovered`)
  }, 300)
}

const renderMenuItem = (
  data: MenuItem,
  index: number,
  renderDuplicateAlias = false
) => {
  switch (data.contentType) {
    case `ContentMenuItem`:
      const itemNavData: renderData = JSON.parse(data.render)
      if (data.contentMenuItemArrow) itemNavData.name += ` ->`
      return (
        <li onMouseEnter={(e) => addHoveredClass(e.target as Element)}
          onMouseLeave={(e) => removeHoveredClass(e.target as Element)}
          key={index}
          className={data.contentMenuItemArrow ? `Submenu-link Submenu-link--arrowLink` : `Submenu-link`}>
          <PuxLink
            activeClassName='is-active'
            partiallyActive={true}
            to={`${getLocalizedUrl(itemNavData.url)}`}
          >
            {!data.contentMenuItemArrow && (<div className="Submenu-contentIcon">
              {(data.contentMenuItemMediaField?.resizePaths[0] && data.contentMenuItemMediaField?.type?.includes(`image/svg+xml`)) ?
                <img
                  src={data.contentMenuItemMediaField?.resizePaths[0]}
                  alt="Category Icon"
                />
                :
                <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.99986 11.9093C10.004 11.9093 10.8181 11.0952 10.8181 10.091C10.8181 9.08687 10.004 8.27283 8.99986 8.27283C7.99569 8.27283 7.18164 9.08687 7.18164 10.091C7.18164 11.0952 7.99569 11.9093 8.99986 11.9093Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.99986 4.63647C10.004 4.63647 10.8181 3.82243 10.8181 2.81825C10.8181 1.81408 10.004 1.00003 8.99986 1.00003C7.99569 1.00003 7.18164 1.81408 7.18164 2.81825C7.18164 3.82243 7.99569 4.63647 8.99986 4.63647Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.81822 8.2728C3.8224 8.2728 4.63644 7.45875 4.63644 6.45457C4.63644 5.4504 3.8224 4.63635 2.81822 4.63635C1.81405 4.63635 1 5.4504 1 6.45457C1 7.45875 1.81405 8.2728 2.81822 8.2728Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.1815 8.2728C16.1857 8.2728 16.9997 7.45875 16.9997 6.45457C16.9997 5.4504 16.1857 4.63635 15.1815 4.63635C14.1773 4.63635 13.3633 5.4504 13.3633 6.45457C13.3633 7.45875 14.1773 8.2728 15.1815 8.2728Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.88477 17.0002C5.48405 15.3056 7.10009 14.091 9.00049 14.091C10.9009 14.091 12.5169 15.3056 13.1162 17.0002" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }
            </div>)}
            <div className="Submenu-contentTitle">
              {renderDuplicateAlias
                ? data.contentMenuItemDuplicateAlias
                  ? data.contentMenuItemDuplicateAlias
                  : decode(itemNavData.name)
                : decode(itemNavData.name)}
            </div>
            {data.contentMenuItemDescription && !data.contentMenuItemArrow ? <div className="Submenu-contentText">{data.contentMenuItemDescription}</div> : null}
          </PuxLink>
        </li>
      )
    case `LinkMenuItem`:
      return (
        <li onMouseEnter={(e) => addHoveredClass(e.target as Element)}
          onMouseLeave={(e) => removeHoveredClass(e.target as Element)}
          key={index}
          className={data.linkMenuItemArrow ? `Submenu-link Submenu-link--arrowLink` : `Submenu-link`}>
          <a href={data.linkMenuItem?.url} target='_blank' rel='noopener'>
            {!data.contentMenuItemArrow && (<div className="Submenu-contentIcon">
              {(data.linkMenuItemMediaField?.resizePaths[0] && data.linkMenuItemMediaField?.type?.includes(`image/svg+xml`)) ?
                <img
                  src={data.linkMenuItemMediaField?.resizePaths[0]}
                  alt="Category Icon"
                />
                :
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.99986 11.9093C10.004 11.9093 10.8181 11.0952 10.8181 10.091C10.8181 9.08687 10.004 8.27283 8.99986 8.27283C7.99569 8.27283 7.18164 9.08687 7.18164 10.091C7.18164 11.0952 7.99569 11.9093 8.99986 11.9093Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.99986 4.63647C10.004 4.63647 10.8181 3.82243 10.8181 2.81825C10.8181 1.81408 10.004 1.00003 8.99986 1.00003C7.99569 1.00003 7.18164 1.81408 7.18164 2.81825C7.18164 3.82243 7.99569 4.63647 8.99986 4.63647Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.81822 8.2728C3.8224 8.2728 4.63644 7.45875 4.63644 6.45457C4.63644 5.4504 3.8224 4.63635 2.81822 4.63635C1.81405 4.63635 1 5.4504 1 6.45457C1 7.45875 1.81405 8.2728 2.81822 8.2728Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.1815 8.2728C16.1857 8.2728 16.9997 7.45875 16.9997 6.45457C16.9997 5.4504 16.1857 4.63635 15.1815 4.63635C14.1773 4.63635 13.3633 5.4504 13.3633 6.45457C13.3633 7.45875 14.1773 8.2728 15.1815 8.2728Z" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.88477 17.0002C5.48405 15.3056 7.10009 14.091 9.00049 14.091C10.9009 14.091 12.5169 15.3056 13.1162 17.0002" stroke="#0D65F2" strokeWidth="1.54677" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
            </div>)}
            <div className="Submenu-contentTitle">
              {`${data.linkMenuItem?.name}${data.linkMenuItemArrow ? ` ->` : ``}`}
            </div>
            {data.linkMenuItemDescription && !data.linkMenuItemArrow ? <div className="Submenu-contentText">{data.linkMenuItemDescription}</div> : null}
          </a>
        </li>
      )
    default:
      return null
  }
}

const renderCategory = (data: MenuCategory, index: number) => {
  if (data.menuItemsList !== null) {
    const classList = [`Submenu-contentCategory`]

    if (data.categoryItemColumns) classList.push(data.categoryItemColumns)

    if (data.contentType && data.contentType === `PuxMegamenuColumn`) {
      return (
        <div className="Submenu-verticalColumn">
          {data.menuItemsList.menuItems.map((menuItem, menuItemIndex) =>
            renderCategory(menuItem, menuItemIndex)
          )}
        </div>
      )
    // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      const navLinkUrl = data.categoryItemContentLink?.url?.find(x => x) && data.categoryItemContentLink?.url?.find(x => x) !== "" ? data.categoryItemContentLink.url[0] : ""
      return (
        <div className={classList.join(" ")} key={index}>
          {data.categoryItemContentLink !== null
            ?
            <div className='Submenu-contentHeading--link'>
              <PuxLink
                to={data.categoryItemContentLink?.internal ? `${getLocalizedUrl(navLinkUrl)}` : navLinkUrl}
              >
                {(data.categoryItemContentLink?.text?.find(x => x) && data.categoryItemContentLink?.text?.find(x => x) !== "") ? data.categoryItemContentLink.text.find(x => x) : data.categoryItemName}
                <PuxIconBuilder icon='ArrowRightIcon' />
              </PuxLink>
            </div>
            :
            <div className='Submenu-contentHeading'>
              {data.categoryItemName}
            </div>
          }



          {data.categoryItemDescription && (
            <div className='Submenu-contentDescription'>
              {data.categoryItemDescription}
            </div>
          )}
          <nav>
            <ul>
              {data?.menuItemsList?.menuItems?.map((menuItem, index) =>
                renderMenuItem(menuItem, index)
              )}
            </ul>
          </nav>
        </div>
      )
    }
  } else {
    return null
  }
}

const renderSubmenu = (data: TopMenuItem, hoverFnc: any) => {
  const isSimple = data.menuItemsList != null && data.menuItemsList.menuItems.length

  const classList = [`Submenu`]

  if (isSimple) classList.push(`is-simple`)

  return (
    <div
      className={classList.join(` `)}
      onMouseEnter={() => hoverFnc(true)}
      onMouseLeave={() => {
        setTimeout(() => {
          hoverFnc(false)
        }, 300)
      }}
    >
      <div className={`Submenu-content`}>
        {data.menuItemsList?.menuItems.map((category, index) =>
          renderCategory(category, index)
        )}
      </div>
    </div>
  )
}

const renderTopMenuItem = (data: TopMenuItem, index: number) => {
  // const [expanded, setExpanded] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)
  const itemNavData: renderData = JSON.parse(data.render)
  const location = useLocation()
  const pathname = location.pathname
  const isInternal = itemNavData.internal ?? true
  const hasSubmenu = data.menuItemsList != null && data.menuItemsList.menuItems.length

  const classList: string[] = []

  if (hasSubmenu) {
    classList.push(`has-submenu`)
  }

  // if (expanded) classList.push(`is-expanded`)

  if (hovered) classList.push(`is-hovered`)

  useEffect(() => {
    document.querySelector(`.Submenu`)?.classList.remove(`has-scroll`)

    if (hovered) {
      const submenu = document.querySelector(`.has-submenu.is-hovered .Submenu`)
      const submenuContent = document.querySelector(`.has-submenu.is-hovered .Submenu-content`)
      if (!submenuContent) return

      const hasVerticalScrollbar = submenuContent.scrollHeight > submenuContent.clientHeight;

      if (hasVerticalScrollbar) {
        submenu?.classList.add(`has-scroll`)
      }
    }
  }, [hovered])


  return (
    <li
      className={classList.join(` `)}
      key={index}
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => setHovered(false)}
    >
      {itemNavData.url.startsWith(`#`) &&
        <PuxAnchorLink
          to={pathname + itemNavData.url}
        >
          {decode(itemNavData.name)}
        </PuxAnchorLink>
      }
      {!itemNavData.url.startsWith(`#`) && isInternal &&
        <PuxLink
          activeClassName='is-active'
          partiallyActive={true}
          to={`${getLocalizedUrl(itemNavData.url)}`}
        >
          {decode(itemNavData.name)}
        </PuxLink>
      }
      {!itemNavData.url.startsWith(`#`) && !isInternal &&
        <a href={itemNavData.url}>
          {decode(itemNavData.name)}
        </a>
      }
      {hasSubmenu ? renderSubmenu(data, setHovered) : null}
    </li>
  )
}

const HeaderNav: FunctionComponent<HeaderNavProps> = (props) => {
  return (
    <nav className={`Header-menu Header-menuDefault`}>
      <ul>
        {props?.headerData?.menuItems?.map((item, index) => renderTopMenuItem(item, index))}
      </ul>
    </nav>
  )
}

export default HeaderNav
